import React, { useState, useEffect, useContext } from 'react';
import { Button, Select, MenuItem, FormControl } from '@mui/material';
import CalendarContext from './CalendarContext';

const CheckItemResponse = ({checkItem, onAnswer}) => {
  const [value, setValue] = useState(undefined);
  const { fixedLayout } = useContext(CalendarContext);

  useEffect(() => {
    if (value) {
      onAnswer(value);
    }
  }, [value]);

  return (
    <div id='checkItemResponse' style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center' }}>
      {checkItem.action === 'DEFAULT' &&
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={() => onAnswer('OK')}>OK</Button>
        </div>
      }
      {checkItem.action === 'ALTERNATIVE' &&
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button variant="outlined" onClick={() => onAnswer('YES')}>Yes</Button>
          <Button variant="outlined" onClick={() => onAnswer('NO')}>No</Button>
        </div>
      }
      {checkItem.action === 'SINGLE_SELECT' &&
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div style={{width: fixedLayout ? '85%' :'50%'}}>
            <FormControl fullWidth>
              <Select value={value}
                onChange={(event) => setValue(event.target.value)}
                displayEmpty
              >
                <MenuItem value={undefined}>
                  <em>Select ...</em>
                </MenuItem>
                {checkItem.options.map((option) => {
                  return <MenuItem
                    key={option}
                    value={option}
                  >
                    {option}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </div>
        </div>
      }
    </div>      

  );
}
export default CheckItemResponse;