import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoonLoader from "react-spinners/MoonLoader";
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import CalendarContext from './CalendarContext';
import ChecklistResponse from './ChecklistResponse';
import { auth } from '../firebase';
import { api } from '../utils';
import userIcon from '../assets/user.png';
import acceptedIcon from '../assets/accepted.png';
import declinedIcon from '../assets/declined.png';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const colors = {
  'SCHEDULED': { border: 'transparent', background: 'transparent' },
  'AVAILABLE': { border: '#4EB0F9', background: '#4EB0F966' },
  'BEGINNING SOON': { border: '#F3C34E', background: '#F3C34E66' },
  'CHECK-IN MISSING': { border: '#F3C34E', background: '#F3C34E66' },
  'ENDING SOON': { border: '#DE5D56', background: '#DE5D5666' },
  'IN USE': { border: '#DE5D56', background: '#DE5D5666' },
}

const responseStatuses = {
  'accepted': 'Accepted',
  'needsAction': 'Not Responded',
  'tentative': 'Tentatively Accepted',
}

// const deviceWidth = window.innerWidth;
// const deviceHeight = window.innerHeight;
// const deviceHeight =
//   Platform.OS === "ios"
//     ? Dimensions.get("window").height
//     : require("react-native-extra-dimensions-android").get(
//         "REAL_WINDOW_HEIGHT"
//       );

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  row: {
    display: 'flex',
    flex: '0 0 auto',
    padding: '20px 30px',
    gap: '20px',
  },
  backButton: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    borderWidth: 2,
    borderColor: 'white',
    borderRadius: 5,
    paddingVertical: 10,
    marginRight: 20,
    backgroundColor: '#fff6',
  },
  resourceName: {
    color: 'white',
    fontSize: 35,
    fontWeight: 'bold',
  },
  resourceCapacity: {
    color: 'white',
    fontSize: 24,
    marginLeft: 15,
  },
  status: {
    textAlign: 'center',
    color: 'white',
    fontSize: 60,
    fontWeight: 'bold',
  },
  responseStatus: {
    color: 'grey',
    fontSize: 30,
    marginLeft: 25,
  },
  event: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '20px'
  },
  eventDetail: {
    backgroundColor: '#FFFFFF40',
    // margin: 15,
    padding: '15px 30px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflowY: 'scroll'
  },
  eventSummary: {
    fontSize: 26,
    fontWeight: 'bold',
    color: 'white',
    margin: '10px 0',
  },
  eventDateTime: {
    fontSize: 20,
    color: 'white',
    marginVertical: 10,
  },
  eventDescription: {
    fontSize: 20,
    color: 'white',
    marginTop: 20,
    marginBottom: 30,
  },
  eventAttendees: {
    backgroundColor: '#FFFFFF40',
    // margin: 15,
    padding: '15px 30px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    wordBreak: 'break-all',
    overflowY: 'scroll'
  },
  eventAttendeesTotal: {
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
  },
  eventAttendeesSubtotal: {
    color: 'white',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 15,
  },
  eventAttendee: {
    display: 'flex',
    flex: 1,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  eventAttendeePhoto: {
    width: 36,
    height: 36,
    borderRadius: 18,
    marginRight: 15,
  },
  eventAttendeeResponseStatus: {
    // position: 'absolute',
    width: 16,
    height: 16,
    borderRadius: 8,
    marginRight: 5,
    // left: 24,
    // top: 24,
  },
  eventAttendeeName: {
    fontSize: 20,
    fontWeight: 500,
    color: 'white',
    flex: 1
  },
  upcomingEvent: {
    padding: '30px 45px',
  },
  upcomingEventText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
  },
  upcomingEventSummary: {
    fontSize: 20,
    color: 'white',
    marginTop: 15,
  },
  actionButton: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    border: "solid 3px #ffffff",
    // margin: 10,
    borderRadius: 5,
    backgroundColor: '#fff6',
    padding: '20px 40px',
    width: '100%',
    minWidth: '190px',
    lineHeight: '2rem',
  },
  timeDropdown: {
    fontSize: 24,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    border: "solid 3px #ffffff",
    //margin: 10,
    borderRadius: 5,
    padding: '20px 45px',
    minHeight:'1rem',
  },
  timeDropdownText: {
    lineHeight: '2rem',
    paddingRight: '45px!important'
  },
  timeDropdownContainer: {
    width: '100%',
    "& .MuiSvgIcon-root": { color: "white", right: "10%" }
  },
  spinner: {
    //backgroundColor: '#00000080',
    //flex: 1,
    //justifyContent: 'space-around',
    position: 'absolute',
    left: '40%',
    top: '40%',
  },
  alertTitle: {
    color: 'black',
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  alertMessage: {
    color: 'black',
    fontSize: 21,
    lineHeight: 30,
  },
  checklistModalContainer: {
    content: {
      position: 'inherit',
      inset: 'unset',
      padding: 0,
      margin: 0,
      height: '100%',
      width: '100%'
    }
  }
};

const Event = ({ status, event }) => {
  const { settings, device, capacity, detailsVisible, fixedLayout } = useContext(CalendarContext);

  if (status === 'AVAILABLE' || event.visibility === 'private') {
    return null;
  }

  const attendees = (device.calendar && event.attendees) ? event.attendees.filter((attendee) => !attendee.resource) : [];
  return (
    <div id='EventContent' style={styles.event}>
      <div style={{ /* flex: 0.6, */ display: 'flex', width: '60%' }}>
        <div style={fixedLayout ? styles.eventDetail : Object.assign({ maxHeight: 480 }, styles.eventDetail)}>
          <div style={styles.eventSummary}>{detailsVisible && device.summaryVisible ? event.summary : 'Reserved'}</div>
          <div style={styles.eventDateTime}>{dayjs(event.start.dateTime).tz(settings.timezone).format('MM/DD/YYYY H:mm')}-{dayjs(event.end.dateTime).tz(settings.timezone).format('H:mm')}</div>
          {detailsVisible && device.descriptionVisible &&
            <div style={styles.eventDescription}>{event.description}</div>
          }
        </div>
      </div>
      <div style={{ /* flex: 0.4, */ display: 'flex', width: '40%' }}>
        {device.attendeesVisible && /* attendees.length > 0 && */
          <div style={ Object.assign(fixedLayout ? {} : { maxHeight: 480 }, styles.eventAttendees)}>
            {attendees.length > 0 ?
              <>
                <span style={Object.assign(capacity && capacity < attendees.length ? { color: 'red' } : {}, styles.eventAttendeesTotal)}>{attendees.length > 1 ? "Participants" : "Participant"}: {attendees.length}</span>
                <div style={styles.eventAttendeesSubtotal}>
                  {attendees.filter((attendee) => attendee.responseStatus === 'accepted').length > 0 && (
                    <span style={{ marginRight: '10px' }}>
                      Yes: {attendees.filter((attendee) => attendee.responseStatus === 'accepted').length}
                    </span>
                  )}
                  {attendees.filter((attendee) => attendee.responseStatus === 'declined').length > 0 && (
                    <span style={{ marginRight: '10px' }}>
                      No: {attendees.filter((attendee) => attendee.responseStatus === 'declined').length}
                    </span>
                  )}
                  {attendees.filter((attendee) => attendee.responseStatus === 'tentative').length > 0 && (
                    <span style={{ marginRight: '10px' }}>
                      Maybe: {attendees.filter((attendee) => attendee.responseStatus === 'tentative').length}
                    </span>
                  )}
                  {attendees.filter((attendee) => attendee.responseStatus === 'needsAction').length > 0 && (
                    <span style={{ marginRight: '10px' }}>
                      No Response: {attendees.filter((attendee) => attendee.responseStatus === 'needsAction').length}
                    </span>
                  )}
                </div>
                {attendees.length > 0 && (
                  <div>
                    {attendees.filter(attendee => attendee.responseStatus === 'accepted').map((attendee) => (
                      <div key={attendee.email} style={styles.eventAttendee}>
                        <img alt='device' style={styles.eventAttendeePhoto} src={attendee.thumbnailPhotoUrl ?? userIcon} />
                        <img alt='accepted' style={styles.eventAttendeeResponseStatus} src={acceptedIcon} />
                        <span style={styles.eventAttendeeName}>{attendee.displayName ? attendee.displayName : attendee.email}</span>
                      </div>
                    ))}
                    {attendees.filter(attendee => attendee.responseStatus === 'declined').map((attendee) => (
                      <div key={attendee.email} style={styles.eventAttendee}>
                        <img alt='device' style={styles.eventAttendeePhoto} src={attendee.thumbnailPhotoUrl ?? userIcon} />
                        <img alt='declined' style={styles.eventAttendeeResponseStatus} src={declinedIcon} />
                        <span style={styles.eventAttendeeName}>{attendee.displayName ? attendee.displayName : attendee.email}</span>
                      </div>
                    ))}
                    {attendees.filter(attendee => attendee.responseStatus === 'tentative').map((attendee) => (
                      <div key={attendee.email} style={styles.eventAttendee}>
                        <img alt='device' style={styles.eventAttendeePhoto} src={attendee.thumbnailPhotoUrl ?? userIcon} />
                        <span style={styles.eventAttendeeName}>{attendee.displayName ? attendee.displayName : attendee.email}</span>
                      </div>
                    ))}
                    {attendees.filter(attendee => attendee.responseStatus !== 'accepted' && attendee.responseStatus !== 'declined' && attendee.responseStatus !== 'tentative').map((attendee) => (
                      <div key={attendee.email} style={styles.eventAttendee}>
                        <img alt='device' style={styles.eventAttendeePhoto} src={attendee.thumbnailPhotoUrl ?? userIcon} />
                        <span style={styles.eventAttendeeName}>{attendee.displayName ? attendee.displayName : attendee.email}</span>
                      </div>
                    ))}
                  </div>
                )}


                
              </> :
              <>
                <div style={styles.eventAttendeesSubtotal}>This event was booked directly from this device.</div>
              </>
            }
          </div>
        }
      </div>
    </div>
  );
}

const checkInEvent = (event, checkedInEvents, setCheckedInEvents, showCheckInMessage) => {
  showCheckInMessage();
  setCheckedInEvents(new Set([...checkedInEvents, event.id]));
}

const UpcomingEvent = ({ event, showCheckInMessage }) => {
  const { checkedInEvents, setCheckedInEvents, settings } = useContext(CalendarContext);

  const summary = `Next: ${event.summary} (${dayjs(event.start.dateTime).tz(settings.timezone).format('H:mm')}-${dayjs(event.end.dateTime).tz(settings.timezone).format('H:mm')})`;

  if (checkedInEvents.has(event.id)) {
    return (
      <div style={styles.upcomingEvent}>
        <div style={styles.upcomingEventText}>The next event was already checked in.</div>
        <div style={styles.upcomingEventSummary}>{summary}</div>
      </div>
    )
  }

  return (
    <div onClick={() => checkInEvent(event, checkedInEvents, setCheckedInEvents, showCheckInMessage)}>
      <div style={styles.upcomingEvent}>
        <div style={styles.upcomingEventText}>Want to check in to the next event? Tap here now.</div>
        <div style={styles.upcomingEventSummary}>{summary}</div>
      </div>
    </div>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': styles.timeDropdown,
  '& .MuiSelect-select': styles.timeDropdownText
}));

const TimeDropdown = ({ value, setValue }) => {
  //console.log('TimeDropdown:' + value)
  return (
    <Select onChange={(event) => setValue(event.target.value)}
      value={value}
      input={<BootstrapInput />}
      sx={styles.timeDropdownContainer}
      IconComponent={ExpandMoreIcon}
      >
      <MenuItem value={5}>5 mins</MenuItem>
      <MenuItem value={15}>15 mins</MenuItem>
      <MenuItem value={30}>30 mins</MenuItem>
      <MenuItem value={60}>1 hour</MenuItem>
    </Select>
);
}

const Actions = ({ status, event, setLoading, showCheckInMessage, showBookNowMessage, showEndingMessage }) => {
  const { device, setSelectedEvent, checkedInEvents, setCheckedInEvents, now, fixedLayout, events } = useContext(CalendarContext);
  const [minutesToBook, setMinutesToBook] = useState(30);
  const [minutesToExtend, setMinutesToExtend] = useState(15);
  const [showChecklistModal, setShowChecklistModal] = useState(false);

  const endEvent = (event) => {
    const endEventInternal = (eventApi) => {
      setLoading(true);
      eventApi()
        .then(() => {
          checkedInEvents.delete(event.id);
          setSelectedEvent(null);
          setCheckedInEvents(new Set(checkedInEvents));
          toast.success('Event has been successfully ended.');
          showEndingMessage();
        })
        .catch((err) => toast(err.message))
        .finally(() => setLoading(false));
    };
    if (dayjs().isBefore(dayjs(event.start.dateTime))) {
      alert('', 'Are you sure that you make this event ended?',
        [
          { text: 'Cancel', style: 'cancel' },
          { text: 'OK', onPress: () => endEventInternal(() => api(`/api/delete_calendar_event?eventId=${event.id}`)) },
        ],
      );
    } else {
      endEventInternal(() => api(`/api/update_calendar_event?eventId=${event.id}`, { end: { dateTime: dayjs().toISOString() } }));
    }
  }

  const bookEvent = (showBookNowMessage) => {
    setLoading(true);
    api('/api/add_calendar_event', { summary: 'Reserved Event', start: { dateTime: dayjs().toISOString() }, end: { dateTime: dayjs().add(minutesToBook, 'minutes').toISOString() } })
      .then((resp) => {
        setCheckedInEvents(new Set([...checkedInEvents, resp.data.id]));
        toast.success('A new event was successfully booked.');
        showBookNowMessage();
      })
      .then(() => { api('/api/add_device_usage', {deviceId: auth.currentUser.uid})})
      .catch((err) => {
        console.log('error!!', err);
        toast.error(err && err.message ? err.message : 'Some Error');
      })
      .finally(() => setLoading(false));
  }

  const extendEvent = (event) => {
    let nextEvent = null;
    for (const wkEvent of events) {
      if (!nextEvent) {
        if (dayjs(now).isBefore(wkEvent.start.dateTime)) {
          nextEvent = wkEvent;
        }
      } else {
        if (dayjs(nextEvent).isBefore(wkEvent.start.dateTime)) {
          nextEvent = wkEvent;
        }
      }
    }
    console.log('nextEvent:', nextEvent);
    if (nextEvent && dayjs(event.end.dateTime).add(minutesToExtend, 'minutes').isAfter(nextEvent.start.dateTime)) {
      toast.error(`This event cannot be extended so as to avoid the schedule conflict with the following event:[${nextEvent.summary}]`);
      return;
    }
    setLoading(true);
    api(`/api/update_calendar_event?eventId=${event.id}`, { end: { dateTime: dayjs(event.end.dateTime).add(minutesToExtend, 'minutes').toISOString() } })
      .then(() => toast.success('This event was successfully extended.'))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }

  const showChecklistBeforeBookEvent = (funcOnChecklistUnavailable) => {
    console.log('showChecklistBeforeBookEvent:' + device.checklistAvailable);
    if (device.checklistAvailable) {
      setShowChecklistModal(true);
    } else {
      funcOnChecklistUnavailable();
    }
  }

  const showChecklistBeforeEndEvent = (funcOnChecklistUnavailable) => {
    if (device.endingChecklistAvailable) {
      setShowChecklistModal(true);
    } else {
      funcOnChecklistUnavailable();
    }
  }

  //console.log('height:' + deviceHeight + ' width:' + deviceWidth);
  switch (status) {
    case 'AVAILABLE':
      if (!device.bookingAvailable) {
        return null;
      }
      return (
        <>
        <Modal
          isOpen={showChecklistModal}
          style={styles.checklistModalContainer}
        >
          <ChecklistResponse
            device={device}
            checklistId={device.checklistId}
            event={event}
            onClose={() => setShowChecklistModal(false)}
            onComplete={() => bookEvent(showBookNowMessage)}
          />
        </Modal>
        <div style={{ display: 'flex', justifyContent: 'center', minWidth: '190px', flex: fixedLayout ? 2 : undefined }}>
          <TimeDropdown value={minutesToBook} setValue={setMinutesToBook} />
        </div>
        <div style={{ display: 'flex', flex: fixedLayout ? 3 : undefined, justifyContent: 'center' }}>
          <button style={styles.actionButton} onClick={() => showChecklistBeforeBookEvent(() => bookEvent(showBookNowMessage))}>
            <span>Book now</span>
          </button>
        </div>
        </>
      );
    case 'BEGINNING SOON':
    case 'CHECK-IN MISSING':
      if (!device.checkInAvailable) {
        return null;
      }
      return (
        <>
        {!checkedInEvents.has(event.id) &&
        <>
          <Modal
            isOpen={showChecklistModal}
            style={styles.checklistModalContainer}
            >
            <ChecklistResponse
              device={device}
              checklistId={device.checklistId}
              event={event}
              onClose={() => setShowChecklistModal(false)}
              onComplete={() => checkInEvent(event, checkedInEvents, setCheckedInEvents, showCheckInMessage)}
            />
          </Modal>      
          <div style={{ flex: fixedLayout ? 0.5 : undefined }}>
            <button style={styles.actionButton} onClick={() => showChecklistBeforeBookEvent(() => checkInEvent(event, checkedInEvents, setCheckedInEvents, showCheckInMessage))}>
              <span>Check in</span>
            </button>
          </div>
        </>
        }
        {fixedLayout &&
        <div style={{ flex: 0.2 }} />
        }
        <div style={{ flex: fixedLayout ? 0.3 : undefined }}>
          <button style={styles.actionButton} onClick={() => endEvent(event)}>
            <span>End now</span>
          </button>
        </div>
        </>
      );
    case 'SCHEDULED':  
    case 'ENDING SOON':
    case 'IN USE':
      if (status === 'SCHEDULED' && dayjs(event.start.dateTime).isBefore(dayjs(now))) {
        return null;
      }
      return (
        <>
        {device.bookingAvailable &&
        <>
        <div style={{ display: 'flex', justifyContent: 'center', flex: fixedLayout ? 1 : undefined }}>
          <TimeDropdown value={minutesToExtend} setValue={setMinutesToExtend} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', flex: fixedLayout ? 3 : undefined }}>
          <button style={styles.actionButton} onClick={() => extendEvent(event)}>
            <span>Extend</span>
          </button>
        </div>
        </>
        }
        {status !== 'SCHEDULED' && device.bookingAvailable && device.endingAvailable &&
        <div style={{ flex: fixedLayout ? 1 : '', height: fixedLayout ? 'auto' : 30 }} />
        }
        {status !== 'SCHEDULED' && device.endingAvailable &&
        <>
        <Modal
          isOpen={showChecklistModal}
          style={styles.checklistModalContainer}
          >
          <ChecklistResponse
            device={device}
            checklistId={device.endingChecklistId}
            event={event}
            onClose={() => setShowChecklistModal(false)}
            onComplete={() => endEvent(event)}
          />
        </Modal>
        <div style={{ display: 'flex', justifyContent: 'center', flex: fixedLayout ? device.bookingAvailable ? 3 : 0.5 : undefined }}>
          <button style={styles.actionButton} onClick={() => showChecklistBeforeEndEvent(() => endEvent(event))}>
            <span>End now</span>
          </button>
        </div>
        </>
        }
        </>
      );
    default: 
        return null;
  }
}

const AlertMessage = ({ title, message, showMessage, setShowMessage }) =>
  <>
  <Dialog
    open={Boolean(showMessage && message)}
    onClose={() => setShowMessage(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {
        message &&
        message.split(/\r\n|\n/).map(
          line => <><DialogContentText id="alert-dialog-description">{line}</DialogContentText></>
        )
      }
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={() => setShowMessage(false)} >OK</Button>
    </DialogActions>
  </Dialog>
  </>

const CalendarStatus = () => {
  const { calendarState, device, capacity, selectedEvent, setSelectedEvent, fixedLayout } = useContext(CalendarContext);
  const [loading, setLoading] = useState(false);
  const [showCheckInMessage, setShowCheckInMessage] = useState(false);
  const [showBookNowMessage, setShowBookNowMessage] = useState(false);
  const [showEndingMessage, setShowEndingMessage] = useState(false);

  let timerId = null;
  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (!showCheckInMessage && !showEndingMessage && !showBookNowMessage) {
      return;
    }
    timerId = setTimeout(() => {
      if (showCheckInMessage) {
        setShowCheckInMessage(false);
      }
      if (showBookNowMessage) {
        setShowBookNowMessage(false);
      }
      if (showEndingMessage) {
        setShowEndingMessage(false);
      }
    }, 120 * 1000);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [showCheckInMessage, showEndingMessage, showBookNowMessage]);

  let status, targetEvent;
  const currentEvent = (calendarState.status === 'BEGINNING SOON') ? calendarState.upcomingEvent : calendarState.ongoingEvent;
  if (selectedEvent && (!currentEvent || currentEvent.id !== selectedEvent.id)) {
    status = 'SCHEDULED';
    targetEvent = selectedEvent;
  } else {
    status = calendarState.status;
    targetEvent = currentEvent;
  }

  return (
    <div id='calendarStatus' style={fixedLayout ? { display: 'flex', flex: 1, flexDirection: 'column', height: '100%' } : { display: 'flex', flex: 1 }}>
      <AlertMessage title="Please read before checking in." message={device.checkInMessage} showMessage={showCheckInMessage} setShowMessage={setShowCheckInMessage} />
      <AlertMessage title="Please read before booking now." message={device.checkInMessage} showMessage={showBookNowMessage} setShowMessage={setShowBookNowMessage} />
      <AlertMessage title="Please read before ending now." message={device.endingMessage} showMessage={showEndingMessage} setShowMessage={setShowEndingMessage} />
      <div
        id='calendarStatusContent'
        style={Object.assign(
          {
            backgroundColor: colors[status].background,
            border: '5px solid ' + colors[status].border,
            flex: 1,
            overflowY: 'scroll'
          },
          styles.container
        )}
      >
        <MoonLoader
          color="#666"
          loading={loading}
          cssOverride={styles.spinner}
          size={80}
        />
        <div style={{...{ flexDirection: 'row', alignItems: 'center' }, ...styles.row}}>
          {fixedLayout &&
          <div style={{ width: 100 }}>
            {status === 'SCHEDULED' &&
            <Button onClick={() => setSelectedEvent(null)}>
              <span style={styles.backButton}>Back</span>
            </Button>
            }
          </div>
          }
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={styles.resourceName}>{device.displayName}</div>
            {capacity &&
            <div style={styles.resourceCapacity}>(Capacity: {capacity} )</div>
            }
          </div>
          {fixedLayout &&
            <div style={{ width: 100 }} />
          }
        </div>
        <div id='StatusRow' style={{...styles.row, ...{ justifyContent : 'center'}}}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
            <div style={styles.status}>{status}</div>
            {status === 'SCHEDULED' && device.calendar &&
            <div style={styles.responseStatus}>{responseStatuses[targetEvent.responseStatus]}</div>
            }
          </div>
        </div>
        {fixedLayout &&
          <div id='EventRow' style={{...styles.row, ...{ flex: "1 1 auto" }}}>
            <Event status={status} event={targetEvent} />
          </div>
        }
        <div id='ActionRow' style={{ ...styles.row, ...{ flexDirection: fixedLayout ? 'row' : 'column', justifyContent: 'center', alignItems: 'center' }}}>
          <Actions status={status} event={targetEvent} setLoading={setLoading} 
            showCheckInMessage={() => setShowCheckInMessage(true)}
            showBookNowMessage={() => setShowBookNowMessage(true)}
            showEndingMessage={() => setShowEndingMessage(true)} />
        </div>
      </div>
      {status !== 'SCHEDULED' && device.checkInAvailable && calendarState.ongoingEvent && calendarState.upcomingEvent &&
      <div style={Object.assign({ justifyContent: 'center', backgroundColor: colors['BEGINNING SOON'].background, borderColor: colors['BEGINNING SOON'].border }, styles.container)}>
        <UpcomingEvent event={calendarState.upcomingEvent} showCheckInMessage={() => setShowCheckInMessage(true)} />
      </div>
      }
    </div>
    );
}
export default CalendarStatus;
