import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoIosKey } from "react-icons/io";
import { Container, Row, Col } from 'react-grid-system';
import { auth, signInWithCustomToken } from '../firebase';
import logo from '../assets/logo.png';
import image from '../assets/image.png';
import ConfirmationCodeForm from '../components/ConfirmationCodeForm';

const variableStyles = (isTablet) => ({
  heading: {
    fontSize: isTablet ? 34 : 28,
    textAlign: 'center',
    color: '#334155',
    fontWeight: 800,
    letterSpacing: '-0.5px',
    margin: '20px 0px'
  },
  leading: {
    fontSize: isTablet ? 20 : 16,
    textAlign: 'center',
    lineHeight: isTablet ? '28px' : '22px',
    color: '#94a3b8'
  },
  scanButtonText: {
    //flex: 1,
    fontSize: isTablet ? '24px' : '18px',
    fontWeight: 'bold',
    color: '#2563eb'
  },
});

const styles = {
  container: {
    height: '100%'
  },
  containerRow: {
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  introduction: {
    display: 'flex',
    flexDirection: 'column',
  },
  logoRow: {
    width: 'auto',
    paddingTop: 20,
    paddingHorizontal: 30,
    textAlign: 'center'
  },
  logoRowTablet: {
    width: 'auto',
    height: '50%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingHorizontal: 30,
    textAlign: 'center',
  },
  logo: {
    maxWidth: '80%',
    margin: 'auto'
  },
  logoTablet: {
    maxWidth: '80%',
    margin: 'auto'
  },
  imageRow: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 15px',
  },
  image: {
    width: '75%',
    margin: 'auto auto'
  },
  textRow: {
    paddingHorizontal: 30,
    justifyContent: 'center',
  },
  actionsRow: {
    display: 'flex',
    paddingHorizontal: 30,
    justifyContent: 'center',
  },
  scanButton: {
    display: 'flex',
    //flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#2563eb',
    borderWidth: 3,
    borderRadius: 10,
    background: '#eff6ff',
    padding: '15px 20px',
    margin: '30px auto',
  },
  scanButtonTablet: {
    display: 'flex',
    //flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#2563eb',
    borderWidth: 4,
    borderRadius: 12,
    background: '#eff6ff',
    padding: '22px 30px',
    margin: '30px auto',
  },
  scanButtonIcon: {
    fontSize: '28px',
    color: '#2563eb',
  },
  confirmationCodeDialog: {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    }
  },
};

const Auth = ({ isTablet }) => {
  const [scanning, setScanning] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!token) {
      return;
    }
    signInWithCustomToken(auth, token)
      .then((userCredential) => {
        console.log('signed in:', userCredential.user);
      })
      .catch((err) => alert(err.message));
  }, [token]);

  const startScanning = () => {
    setScanning(true);
    setToken('');
  }

  const closeDialog = (data) => {
    if (data) {
      //const json = JSON.parse(data);
      console.log('get token:', data);
      setToken(data.token);
    }
    setScanning(false);
  }

  return (
    <>
      <Modal
        isOpen={scanning}
        onRequestClose={closeDialog}
        style={styles.confirmationCodeDialog}>
        <ConfirmationCodeForm closeDialog={closeDialog} isTablet={isTablet}/>
      </Modal>
      <Container fluid={false} style={styles.container}>
        <Row style={styles.containerRow} align='center' direction='column'>
          <Col md={12} style={isTablet ? styles.logoRowTablet : styles.logoRow}>
            <img style={isTablet ? styles.logoTablet : styles.logo} src={logo} alt='logo'/>
          </Col>
          <Col md={10} style={styles.imageRow}>
            <img style={styles.image} src={image} alt='MeetingRule'/>
          </Col>
          <Col md={10} style={styles.textRow} ><div style={variableStyles(isTablet).heading}>Make the most of your meeting spaces</div></Col>
          <Col md={10}  style={styles.textRow} ><div style={variableStyles(isTablet).leading}>A meeting becomes more prepared, a participant becomes more punctual, and a company becomes more productive.</div></Col>
          <Col md={12} style={styles.actionsRow}>
            <button
              style={isTablet ? styles.scanButtonTablet : styles.scanButton}
              onClick={startScanning}
            >
              <span style={variableStyles(isTablet).scanButtonText}>Enter code to activate</span>
            </button>
            {/*__DEV__ && <TextInput value={token} onChangeText={(value) => setToken(value)} style={{ width: 350, height: 30, borderWidth: 1, padding: 3, marginTop: 10 }} />*/}
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Auth;
