import { useEffect, useState } from "react";
import { auth } from './firebase';
import axios from 'axios';

// APIを実行する
// Firebase Authenticationの認証情報をHTTPヘッダーに乗せる
export const api = (path, data) => auth.currentUser.getIdToken()
  .then((token) => axios.post((process.env.REACT_APP_API_ENDPOINT_URL) + path, data, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + token,
    }
  }))
  .catch((err) => {
    console.log('calling api failed.', err);
    console.log('firebase host:' + process.env.REACT_APP_API_ENDPOINT_URL);
    throw err.response.data ? err.response.data.error : err;
  });

// APIを実行する
export const apiWithoutAuth = (path, data) => axios.post(
    (process.env.REACT_APP_API_ENDPOINT_URL) + path
    , data
    , {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      }
    }
  )
  .catch((err) => {
    console.log('calling api failed.', err);
    console.log('firebase host:' + process.env.REACT_APP_API_ENDPOINT_URL);
    throw err.response ? err.response.data.error : err;
  });

export const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const keyValue = localStorage.getItem(key);
    return keyValue ? JSON.parse(keyValue, (k, v) => {
        if (typeof v === "object" && v !== null) {
          if (v.dataType === "Set") {
            return new Set(v.value)
          }
        }
        return v
      })
      : initialValue;
    //return keyValue ? JSON.parse(keyValue) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value, (_key, value) => (value instanceof Set ? {dataType: 'Set', value:[...value]} : value)));
  }, [key, value]);

  return [value, setValue];
};
