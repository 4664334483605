import React, { useState, useEffect } from 'react';
import { IconButton , LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { isIOS } from 'react-device-detect';
import { auth, db } from '../firebase';
import { api } from '../utils';
import { toast } from 'react-toastify';
import CheckItemResponse from './CheckItemResponse';
import { doc, onSnapshot } from "firebase/firestore";

const ChecklistResponse = ({device, checklistId, event, onClose, onComplete}) => {
  const [adminUid, setAdminUid] = useState(undefined);
  const [checklist, setChecklist] = useState(undefined);
  const [checkItemIdx, setCheckItemIdx] = useState(0);
  const [responseId, setResponseId] = useState(undefined);
  const [responses, setResponses] = useState([]);
  const [remainingSecs, setRemainingSecs] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  //let responseId;
  let checklistTimerId = null;

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: "space-evenly",
      flex: 1,
      alignItems: "center",
      borderWidth: 1,
    }, 
    checklistModal: {
      backgroundColor: '#F9FAFC',
      display: 'flex',
      //flex: 1,
      margin: 0,
      width: window.innerWidth,
      height: window.innerHeight,
    },
    checklistModalAndroid: {
      backgroundColor: '#F9FAFC',
      display: 'flex',
      //flex: 1,
      margin: '0 20px',
      width: window.innerWidth - 40,
      height: window.innerHeight,
    },
    closeIcon: {
      color: 'black',
      margin: '15px',
      marginTop: '16px',
    },
    error: {
      fontSize: 24,
      color: '#f0506e',
      backgroundColor: '#fef4f6',
      marginBottom: 30,
      marginHorizontal: 30,
      padding: 10,
    },
    deviceMessage: {
      color: '#607D8B',
      fontSize: 16,
      margin: '0 5px 5px 5px',
    },
    deviceName: {
      color: '#263238',
      fontWeight: 'bold',
      fontSize: 24,
      margin: 8,
    },
    calendarName: {
      color: '#263238',
      fontSize: 20,
      margin: 10,
    },
    checkitemTitle: {
      color: '#263238',
      fontSize: 26,
      fontWeight: 'bold',
      margin: '0 8% 10px 8%',
    },
    checkitemMessage: {
      color: '#607D8B',
      fontSize: 20,
      lineHeight: '30px',
      margin: '5px 8% 15px 8%'
    },
    progressTitle: {
      textAlign: 'center',
      color: 'silver',
      fontSize: 16,
      margin: 10,
    },
    remainingMessage: {
      alignSelf: 'flex-start',
      color: 'dimgray',
      fontSize: 15,
      margin: '5px 20px 5px 20px'
      //marginVertical: 5,
      //marginHorizontal: 20,
    },
  };

  useEffect(() => {
    auth.currentUser.getIdTokenResult()
      .then((idToken) => setAdminUid(idToken.claims.adminUid));
  }, []);

  useEffect(() => {
    if (!adminUid) {
      return;
    }
    const userRef = doc(db, 'users', adminUid);
    const checklistRef = doc(userRef, 'checklists', checklistId);
    const unsubscribe = onSnapshot(checklistRef, (doc) => {
      setChecklist({...doc.data(), id: doc.id });
    });
    return () => unsubscribe();
  }, [adminUid]);

  useEffect(() => {
    let isMounted = true;
    if (checklist && checklist.mustBeAnsweredWithin) {
      setRemainingSecs(checklist.answerThresholdSeconds);
    }
    if (checklist) {
      api(`/api/add_checklist_response`, { answers: [], checklistId: checklist.id, event: event ? event.summary : ''})
        .then((res) => {
          if (isMounted) {
            setResponseId(res.data.id);
          }
        })
        .catch((err) => setErrorMessage(err.message ?? err))
        .finally(() => {});
    }
    return () => { isMounted = false }
  }, [checklist]);

  useEffect(() => {
    if (checklistTimerId) {
      clearTimeout(checklistTimerId);
    }
    checklistTimerId = setTimeout(() => {
      onTimeout();
    }, 1000);

  }, [remainingSecs]);

  const onTimeout = () => {
    //console.log('onTimeout:' + remainingSecs + 's remains, timerId:' + checklistTimerId);
    if (!remainingSecs || remainingSecs <= 0) return;

    if (remainingSecs <= 1) {
      onClose();
      toast.error('Your response is timeout.');
      return;
    }
    setRemainingSecs(remainingSecs - 1);
  };

  useEffect(() => {
    if (!checklist) {
      return;
    }

    var idx = checkItemIdx;
    if (idx < checklist.checkItems.length) {
      idx++;
    }
    if (idx === checklist.checkItems.length) {
      api(`/api/update_checklist_response`, { id: responseId, answers: responses, checklistId: checklist.id, isCompleted: true})
        .then(() => {
          onComplete();
          onClose();
          toast.success('Your answer is successfully delivered to the administrator.');
        })
        .catch((err) => setErrorMessage(err.message))
        .finally(() => {});
      return;
    }
    setCheckItemIdx(idx);
  }, [responses]);

  const doResponse = (value) => {
    setResponses([
      ...responses,
      {checkItemId: checklist.checkItems[checkItemIdx].id,
      responsedAt: new Date(),
      value}
    ]);
  };

  const onCancel = () => {
    if (checklistTimerId) {
      clearTimeout(checklistTimerId);    
    }
    onClose();
  };

  return (
    <div style={isIOS ? styles.checklistModal : styles.checklistModalAndroid}>
      <div style={styles.container}>
        <div style={{ flexDirection: 'column', display: 'flex', flex: 1.3, width: '100%' }}>
          <div style={{ flex: 1.1, alignSelf: 'flex-end'}}>
            <IconButton onClick={() => {onCancel()}}>
              <CloseIcon sx={styles.closeIcon} color='white' name='close'></CloseIcon>
            </IconButton>
          </div>
          <div style={{ flex: 0.9 }}>
            {checklist && checklist.mustBeAnsweredWithin && <span style={styles.remainingMessage}>Remaining {Math.floor(remainingSecs / 60)}:{('00' + (remainingSecs % 60)).slice(-2)}</span>}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: isIOS ? 2 : 2.5, width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', backgroundColor: '#FAFAFA'}}>
            {errorMessage && <span style={styles.error}>{errorMessage}</span>}
            <div style={styles.deviceMessage}>You're now at ...</div>
            <div style={styles.deviceName}>{device ? device.displayName: ''}</div>
            <div style={styles.calendarName}>{event ? event.summary : ''}</div>
          </div>
          <div style={{ display: 'flex', flex: isIOS ? 1 : 0.8, justifyContent: 'center', alignItems: 'center'}}>
            {checklist && checklist.checkItems.length > 0 &&
            <>
              <div>
                <div style={styles.progressTitle}>Checking {checkItemIdx + 1} / {checklist.checkItems.length}</div>
                <LinearProgress variant="determinate" value={(checkItemIdx + 1)/checklist.checkItems.length * 100} sx={{ width: 300, height: 12, color: '#2962FF', backgroundColor: '#ECEFF1'}}/>
              </div>
            </>
            }
          </div>
        </div>
        <div style={{ display: 'flex', flex: 3.5, flexDirection: 'column', marginBottom: 0, width: '100%' }}>
          {checklist && checklist.checkItems[checkItemIdx] &&
            <>
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', overflowY: 'auto' }}>
              <div style={{ flex: 1 }}>
                <div style={styles.checkitemTitle}>({checkItemIdx + 1}/{checklist.checkItems.length}) {checklist.checkItems[checkItemIdx].title}</div>
              </div>
              <div style={{ flex: 1.1 }}>
                <div style={styles.checkitemMessage}>{checklist.checkItems[checkItemIdx].description}</div>
              </div>
            </div>
            <div id='checkItemResponseContainer' style={{ display: 'flex', flex: 1}}>
              <CheckItemResponse checkItem={ checklist.checkItems[checkItemIdx] } onAnswer={(value) => {doResponse(value);}} />
            </div>
            </>
          }
        </div>

      </div>
    </div>
  );
}
export default ChecklistResponse;
