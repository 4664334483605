import React, { useState } from 'react';
import { IoIosMail } from "react-icons/io";
import { Button, TextField } from '@mui/material';
import { api } from '../utils';
import { toast } from 'react-toastify';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'space-around',
    margin: 0,
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 30,
    flex: 1
  },
  error: {
    fontSize: 24,
    color: '#f0506e',
    backgroundColor: '#fef4f6',
    marginBottom: 30,
    marginHorizontal: 30,
    padding: 10,
  },
  textInputWrapper: {
    borderColor: 'grey',
    borderWidth: 1,
    borderRadius: 4,
    marginHorizontal: 30,
    flex: 8,
  },
  textInput: {
    textAlignVertical: 'top',
    padding: 10,
    flex: 1,
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '20px 30px',
    flex: 1,
  },
  sendButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 25px',
    borderColor: '#3465F5',
    border: '3px solid',
    borderRadius: 6,
    margin: '16px 0',
  },
  sendButtonDisabled: {
    opacity: 0.5,
  },
  sendButtonIcon: {
    color: '#3465F5',
  },
  sendButtonText: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#3465F5',
    marginLeft: 20,
  },
  cancelButton: {
    padding: '20px 0',
    margin: '16px 40px',
  },
  cancelButtonDisabled: {
    padding: '20px 0',
    margin: '0 40px',
    opacity: 0.5,
  },
  cancelButtonText: {
    fontSize: 20,
    opacity: 0.5,
  },
  spinner: {
    position: 'absolute',
    left: 0,
    right: 0,
  }
};

const ContactForm = ({ closeDialog }) => {
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const submit = () => {
    console.log('Submitting contact form.:' + text);
    setSubmitting(true);
    api(`/api/send_inquiry`, { text: text })
      .then(() => {
        closeDialog();
        toast.success('Your message is successfully delivered to the administrator.');
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setSubmitting(false));
  }

  return (
    <div id='contactForm' style={styles.container}>
      <div style={styles.heading}>Contact to administrator</div>
      {errorMessage &&
      <div style={styles.error}>{errorMessage}</div>
      }
      <div style={styles.textInputWrapper}>
        <TextField fullWidth minRows={8} maxRows={20} editable={true} multiline={true} onChange={(event) => {setText(event.target.value)}} value={text}/>
      </div>
      <div style={styles.actionsRow}>
        <Button sx={submitting ? styles.cancelButtonDisabled : styles.cancelButton} onClick={() => closeDialog()} disabled={submitting}>
          <span style={styles.cancelButtonText}>Cancel</span>
        </Button>
        <Button onClick={submit} disabled={submitting} sx={[styles.sendButton, submitting ? styles.sendButtonDisabled : null]}>
          <IoIosMail name="ios-mail" size={styles.sendButtonText.fontSize} style={styles.sendButtonIcon} />
            <span style={styles.sendButtonText}>Send</span>
        </Button>
        {/* <TouchableWithoutFeedback onPress={submit} disabled={submitting}>
          <div >
            {submitting &&
            <div style={styles.spinner}>
              <ActivityIndicator size="large" color="black" />
            </div>
            }
            <IoIosMail name="ios-mail" size={styles.sendButtonText.fontSize} style={styles.sendButtonIcon} />
            <Text style={styles.sendButtonText}>Send</Text>
          </div>
        </TouchableWithoutFeedback> */}
      </div>
    </div>
  );
}
export default ContactForm;
