import React, { useState } from 'react';
import MoonLoader from "react-spinners/MoonLoader";
import { apiWithoutAuth } from '../utils';
import AuthCode from 'react-auth-code-input';
import { Container, Button } from '@mui/material';
import { toast } from 'react-toastify';

const styles = {
  container: {
    //display: 'flex',
    //flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 15
  },
  heading: {
    textAlign: `center`,
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 40
  },
  error: {
    fontSize: 20,
    color: '#ef4444',
    backgroundColor: '#fef2f2',
    marginBottom: 30,
    marginHorizontal: 30,
    padding: 15,
  },
  actionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 40,
  },
  sendButton: {
    display: 'flex',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: '#2563eb',
    borderWidth: 3,
    borderRadius: 10,
    background: '#eff6ff',
    padding: '15px 20px'
  },
  sendButtonDisabled: {
    opacity: 0.5,
  },
  sendButtonText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#2563eb'
  },
  cancelButton: {
    borderWidth: 0,
    background: "transparent",
    padding: 0,
    margin: 0
  },
  cancelButtonText: {
    fontSize: 20,
    color: '#94a3b8'
  },
  spinner: {
    position: 'absolute',
    left: '40%',
    top: '40%',
  }
};
const ConfirmationCodeForm = ({ isTablet, closeDialog }) => {
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const submit = () => {
    console.log('submitting:' + text);
    setSubmitting(true);
    apiWithoutAuth('/auth/confirm_code', { confirmationCode: text })
      .then((res) => {
        closeDialog(res.data);
        toast.success('Your device is successfully authenticated.');
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(err ? err.message : err);
      })
      .finally(() => setSubmitting(false));
  }
  return (
    <Container style={styles.container}>
      <div style={styles.heading}>Enter a code</div>
      {errorMessage &&
      <div style={styles.error}>{errorMessage}</div>
      }
      <AuthCode 
        allowedCharacters='numeric'
        onChange={setText}
        maxLength={6}
        autoFocus={true}
        containerClassName='auth-input-container'
        inputClassName={`auth-input ${isTablet ? 'auth-input-tablet' : ''}`}
      />
      <div style={styles.actionsRow}>
        <button
          style={styles.cancelButton}
          onClick={() => closeDialog()}
          disabled={submitting}
        >
          <span style={styles.cancelButtonText}>Cancel</span>
        </button>
        <button
          style={{ ...styles.sendButton, ...(submitting ? styles.sendButtonDisabled : {}) }}
          onClick={submit}
          disabled={submitting}
        >
          <span style={styles.sendButtonText}>Confirm</span>
        </button>
      </div>
      <MoonLoader
        color="#94a3b8"
        loading={submitting}
        cssOverride={styles.spinner}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </Container>
  );
}

export default ConfirmationCodeForm;