import React, { useContext } from 'react';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CalendarContext from './CalendarContext';

const styles = {
  container: {
    display: 'flex',
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: 'column'
  },
  currentTime: {
    color: 'white',
    textAlign: 'center',
    fontSize: 40,
    fontWeight: 'bold',
  },
  currentDate: {
    color: 'white',
    textAlign: 'center',
    fontSize: 20,
    marginTop: 10,
    fontWeight: 'bold',
  },
};
dayjs.extend(utc);
dayjs.extend(timezone);

const CalendarDateTime = () => {
  const { settings, now } = useContext(CalendarContext);
  return (
    <div id='calendarDateTime' style={styles.container}>
      <span style={styles.currentTime}>{dayjs(now).tz(settings.timezone).format('HH:mm')}</span>
      <span style={styles.currentDate}>{dayjs(now).tz(settings.timezone).format('ddd, MMM D, YYYY')}</span>
    </div>
  );
}
export default CalendarDateTime;
