import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { mobileVendor, mobileModel, osName, osVersion } from 'react-device-detect';
import {useBattery} from 'react-use';
import { IoIosCog } from "react-icons/io";
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, onSnapshot, Timestamp } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import { api, useLocalStorage } from '../utils';
import CalendarContext from '../components/CalendarContext';
import DailyCalendar from '../components/DailyCalendar';
import CalendarStatus from '../components/CalendarStatus';
import CalendarDateTime from '../components/CalendarDateTime';
import ContactForm from '../components/ContactForm';

dayjs.extend(isBetween);
const dateTimeComponentHeight = 142;
const settingsComponentHeight = 93;
const windowHeight =  Math.min(window.innerWidth, window.innerHeight);

const styles = {
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    display: 'flex'
  },
  backgroundImageView: {
    flex: 1,
  },
  backgroundImage: {
    display: 'inherit',
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.8)',
    resizeMode: 'contain',
  },
  description: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionText: {
    color: 'white',
    fontSize: 20,
  },
  settings: {
    width: 93,
    padding: 20,
  },
  settingsIcon: {
    color: 'white',
    fontSize: 30,
    padding: 10,
  },
  settingsMenuOptions: {
    padding: 10,
  },
  settingsMenuOptionText: {
    fontSize: 18,
  },
  contactFormDialog: {
    content: {
      marginVertical: 0,
      paddingHorizontal: 100,
      paddingBottom: 10,
      maxHeight: windowHeight * 0.8,
      flex: 1,  
    }
  },
  formContainer: {
    flex: 1,
  }
};

const getStatus = (upcomingEvent, ongoingEvent, now, checkedInEvents, device) => {
  if (!upcomingEvent && !ongoingEvent) {
    return 'AVAILABLE';
  }

  if (upcomingEvent && !ongoingEvent) {
    return 'BEGINNING SOON';
  }

  if (device.checkInAvailable && !checkedInEvents.has(ongoingEvent.id)) {
    return 'CHECK-IN MISSING';
  }

  if (dayjs(now).isBetween(dayjs(ongoingEvent.end.dateTime).subtract(10, 'minutes'), ongoingEvent.end.dateTime)) {
    return 'ENDING SOON';
  }

  return 'IN USE';
}

const Settings = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { detailsVisible, setDetailsVisible } = useContext(CalendarContext);
  const [isContactFormDialogOpen, setIsContactFormDialogOpen] = useState(false);
  return (
    <div id='settings' style={styles.settings}>
      {/* <div style={styles.formContainer} behavior='padding'>
      </div> */}
      <Modal style={styles.contactFormDialog} isOpen={isContactFormDialogOpen} onRequestClose={() => setIsContactFormDialogOpen(false)}>
        <ContactForm closeDialog={() => setIsContactFormDialogOpen(false)} />
      </Modal>
      <IconButton onClick={() => setMenuOpen(true)}>
        <IoIosCog size={styles.settingsIcon.fontSize} color={styles.settingsIcon.color} />
      </IconButton>
      <Menu id='settings-menu' sx={styles.settingsMenuOptions} open={menuOpen} onClose={() => setMenuOpen(false)}>
        {detailsVisible ?
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              setDetailsVisible(false);
            }}>
            <span style={styles.settingsMenuOptionText}>Hide the event details</span>
          </MenuItem>
          :
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              setDetailsVisible(true);
            }}
          >
            <span style={styles.settingsMenuOptionText}>Show the event details</span>
          </MenuItem>
        }
        <MenuItem
          onClick={(event) => {
            event.preventDefault();
            window.open(process.env.REACT_APP_ADMIN_URL, "_blank");
          }}
        >
          <span style={styles.settingsMenuOptionText}>Go to Admin</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuOpen(false);
            setIsContactFormDialogOpen(true);
          }}
        >
          <span style={styles.settingsMenuOptionText}>Contact to administrator</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

const Body = () => { 
  const { fixedLayout } = useContext(CalendarContext);
  if (fixedLayout) {
    return (
      <div id='tabletBody' style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <div id='tabletCalendar' style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <CalendarDateTime />
          <DailyCalendar height={window.innerHeight - dateTimeComponentHeight - settingsComponentHeight} />
          <Settings />
        </div>
        <div id='calendarStatus' style={{ display: 'flex', flex: 3 }}>
          <CalendarStatus />
        </div>
      </div>
    );
  }
  return (
    <div id='phoneBody' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{display: 'flex', flex: 1, justifyContent: 'center'}}>
        <CalendarDateTime />
      </div>
      <div style={{display: 'flex', flex: 5}}>
        <CalendarStatus />
      </div>
    </div>
  );
}

const getDeviceInfo = () => ({
  deviceName: mobileVendor,
  modelName: mobileModel,
  osName: osName,
  osVersion: osVersion,
});

const Calendar = ({ isTablet }) => {
  const [adminUid, setAdminUid] = useState(undefined);
  const [settings, setSettings] = useState(undefined);
  const [calendarState, setCalendarState] = useState({ ongoingEvent: null, upcomingEvent: null, status: 'AVAILABLE' });
  const [selectedEvent, setSelectedEvent] = useState(undefined);
  const [detailsVisible, setDetailsVisible] = useState(true);
  const [device, setDevice] = useState(undefined);
  const [capacity, setCapacity] = useState(undefined);
  const [events, setEvents] = useState([]);
  const [checkedInEvents, setCheckedInEvents] = useLocalStorage('checkedInEvents', new Set());
  const [now, setNow] = useState(new Date());
  const [fixedLayout, setFixedLayout] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [batteryLevel, setBatteryLevel] = useState(undefined);
  const [cancelingEvent, setCancelingEvent] = useState(undefined);

  useEffect(() => {
    auth.currentUser.getIdTokenResult()
      .then((idToken) => setAdminUid(idToken.claims.adminUid));
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 1 * 1000);
    //ScreenOrientation.lockAsync(isTablet ? ScreenOrientation.OrientationLock.LANDSCAPE : ScreenOrientation.OrientationLock.PORTRAIT_UP);
    setFixedLayout(isTablet);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!adminUid) {
      return;
    }
    const userRef = doc(db, 'users', adminUid);
    const unsubscribeUser = onSnapshot(userRef, (doc) => {
      setSettings(doc.data());
    });

    const deviceRef = doc(userRef, 'devices', auth.currentUser.uid);
    let unsubscribeDevice = () => {};
    getDoc(deviceRef)
      .then((doc) => {
        if (doc.exists()) {
          return;
        }
        // デバイス新規登録（初回ログイン）
        return setDoc(deviceRef, {
          deviceInfo: getDeviceInfo(),
          authorized: false,
          events: [],
          createdAt: Timestamp.now(),
          syncedAt: Timestamp.now()
        });
      })
      .then(() => {
        unsubscribeDevice = onSnapshot(deviceRef, (doc) => {
          if (!doc.exists()) {
            // デバイス削除
            auth.signOut();
          } else {
            // デバイス更新
            setDevice(doc.data());
          }
        });
      });
    return () => {
      unsubscribeUser();
      unsubscribeDevice();
    }
  }, [adminUid]);

  useEffect(() => {
    if (!device) {
      return;
    }
    const storage = getStorage();
    getDownloadURL(ref(storage, (`users/${adminUid}/devices/${auth.currentUser.uid}/photo`)))
      .then((url) => setPhotoUrl(url))
      .catch(() => {});

    const eventsCompareFn = (a, b) => dayjs(a.start.dateTime).isSame(dayjs(b.start.dateTime)) ? dayjs(a.end.dateTime).diff(dayjs(b.end.dateTime)) : dayjs(a.start.dateTime).diff(dayjs(b.start.dateTime));
    if (device.calendar) {
      const unsubscribe = onSnapshot(device.calendar, async (doc) => {
        setCapacity(doc.data().resource.capacity);
        setEvents((doc.data().events || [])
          .map((event) => ({ ...event, responseStatus: event.temporary ? 'accepted' : event.attendees.find((attendee) => attendee.email === doc.id).responseStatus }))
          .filter((event) => (device.onlyAcceptedEventVisible ? event.responseStatus === 'accepted' : event.responseStatus !== 'declined'))
          .sort(eventsCompareFn)
        );
      });
      return () => unsubscribe();
    } else {
      setEvents((device.events || []).sort(eventsCompareFn));
      return;
    }
  }, [device]);

  const batteryState = useBattery();
  if (device && batteryState.isSupported) {
    if (batteryState.fetched) {
      //console.log('batteryState fetched:' + batteryState.level);
      if (batteryState.level !== batteryLevel) {
        setBatteryLevel(batteryState.level);
      }
    }
  }

  useEffect(() => {
    console.log('batteryLevel:', batteryLevel);
    if (!batteryLevel) {
      return;
    }
    api(`/api/update_battery_level`, { batteryLevel })
    .catch((err) => console.log('saving battery level failed. :', err));
  }, [batteryLevel]);

  useEffect(() => {
    //console.log('useEffect[now, events, checkedInEvents]', device);
    if (!device) {
      return;
    }
    let upcomingEvent = null;
    let ongoingEvent = null;
    for (const event of events) {
      if (dayjs(now).isBetween(event.start.dateTime, event.end.dateTime)) {
        ongoingEvent = event;
      } else if (dayjs(now).isBetween(dayjs(event.start.dateTime).subtract(device.checkInAvailable ? device.checkInMinutesBeforeEventStarts : 10, 'minutes'), event.start.dateTime)) {
        upcomingEvent = event;
      }
      if (upcomingEvent && ongoingEvent) {
        break;
      }
    }
    if (!cancelingEvent && device.checkInAvailable && ongoingEvent && !checkedInEvents.has(ongoingEvent.id) && dayjs(now).isAfter(dayjs(ongoingEvent.start.dateTime).add(device.checkInMinutesAfterEventStarts, 'minutes'))) {
      console.log('Event is forced to be ended:', ongoingEvent);
      setCancelingEvent(ongoingEvent);
      // api(`/api/update_calendar_event?eventId=${ongoingEvent.id}`, { end: { dateTime: dayjs(ongoingEvent.start.dateTime).add(device.checkInMinutesAfterEventStarts, 'minutes').toISOString() } })
      //   .then(() => {
      //     toast('The last schedule was forced to be ended due to the missing check-in. You can always manage the settings on meetingrule.com.');
      //   })
      //   .catch((err) => {
      //     toast(err.message);
      //   });
    }
    let currentStatus = getStatus(upcomingEvent, ongoingEvent, now, checkedInEvents, device);
    setCalendarState({
      upcomingEvent,
      ongoingEvent,
      status: currentStatus,
    });

    if (dayjs(device.syncedAt.toDate()).isBefore(dayjs().subtract(1, 'hour'))) {
      const userRef = doc(db, 'users', adminUid);
      const deviceRef = doc(userRef, 'devices', auth.currentUser.uid);
      updateDoc(deviceRef, {
        deviceInfo: getDeviceInfo(),
        status: currentStatus,
        syncedAt: Timestamp.now()
      });
    }
  }, [now, events, checkedInEvents]);

  useEffect(() => {
    if (cancelingEvent) {
      console.log(`cancelingEvent: Event is forced to be ended:${cancelingEvent.id}`);;
      // setCancelingEvent(null);
      api(`/api/update_calendar_event?eventId=${cancelingEvent.id}`, { end: { dateTime: dayjs(cancelingEvent.start.dateTime).add(device.checkInMinutesAfterEventStarts, 'minutes').toISOString() } })
        .then(() => {
          toast('The last schedule was forced to be ended due to the missing check-in. You can always manage the settings on meetingrule.com.');
          setCancelingEvent(undefined);
        })
        .catch((err) => {
          toast(err.message);
          setCancelingEvent(undefined);
        });
    } else {
      console.log(`cancelingEvent: no event to cancel.`);;
    }
  }, [cancelingEvent]);

  let timerId = null;
  useEffect(() => {
    if (timerId) {
      clearTimeout(timerId);
    }
    if (!selectedEvent) {
      return;
    }
    timerId = setTimeout(() => {
      setSelectedEvent(null);
    }, 30 * 1000);
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    }
  }, [selectedEvent]);

  if (!settings || !device) {
    return null;
  }

  if (!device.authorized) {
    return (
      <div style={styles.container}>
        <div style={styles.description}>
          <div style={styles.descriptionText}>Waiting for adding this device...</div>
          <Button onClick={() => auth.signOut()}>Cancel</Button>
        </div>
      </div>
    );
  }
  if ((!settings.stripe.trial && settings.stripe.status !== 'active') || new Date().getTime() > settings.stripe.subscriptionEndTime.toDate().getTime()) {
    return (
      <div style={styles.container}>
        <div style={styles.description}>
          <div style={styles.descriptionText}>This device is not currently available as there may be something wrong with your organization's subscription. Please directly contact your administrator to make this device available again.</div>
          <Button onClick={() => auth.signOut()}>Sign out</Button>
        </div>
      </div>
    );
  }

  //console.log('photoUrl:' + photoUrl);
  return (
    <CalendarContext.Provider value={{ calendarState, settings, device, capacity, events, checkedInEvents, setCheckedInEvents, selectedEvent, setSelectedEvent, detailsVisible, setDetailsVisible, now, fixedLayout }}>
      <div id='Calendar' style={styles.container}>
        {photoUrl ?
        <div id='backgroundImageView' style={{...{ backgroundImage: `url(${photoUrl})` }, ...styles.backgroundImageView}}>
          <div style={styles.backgroundImage}>
          <Body />
          </div>
        </div>
        :
        <Body />
        }
      </div>
    </CalendarContext.Provider>
  );
}
export default Calendar;
