import { createContext } from "react";

const CalendarContext = createContext({
  calendarState: undefined,
  settings: undefined,
  device: undefined,
  capacity: undefined,
  events: undefined,
  checkedInEvents: undefined,
  setCheckedInEvents: () => {},
  selectedEvent: undefined,
  setSelectedEvent: () => {},
  now: undefined,
  fixedLayout: false,
});
export default CalendarContext;
